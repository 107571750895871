import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './Welcome.module.scss'

const Welcome = () => (
  <BannerSection>
    <BannerContainer className={styles.container}>
      <img className={styles.image} src="/images/cap.svg" alt="Cap" />
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          Your
          <br /> brand to
          <br /> the next
          <br /> level
        </span>
        <p className={styles.description}>
          Welcome to the metaverse
        </p>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default Welcome
