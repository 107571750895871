import classNames from "classnames";
import { metaverseSliderSettings } from "../../constants/sliderSettings";
import BannerContainer from "../../containers/BannerContainer";
import BannerSection from "../../containers/BannerSection";
import Slider from "../Slider/Slider";

import styles from "./Metaverse.module.scss";

export const metaverseVideos = [
  {
    name: "Spatial",
    videoSrc: "https://d2vcyos3v84uue.cloudfront.net/media/image/spatial.mp4",
  },
  {
    name: "Decentraland",
    videoSrc:
      "https://d2vcyos3v84uue.cloudfront.net/media/image/decentraland.mp4",
  },
  {
    name: "RedBee",
    videoSrc:
      "https://d2vcyos3v84uue.cloudfront.net/media/image/redbee.mp4",
  },
];

export const renderMetaverseVideo = (
  metaverseVideo: any,
  slideClass: string
) => (
  <video
    key={metaverseVideo.name}
    muted
    loop
    autoPlay
    controlsList="nodownload"
    className={classNames(slideClass, styles.video)}
    width={`${window.innerWidth}px`}
    playsInline
  >
    <source
      src={metaverseVideo.videoSrc}
      type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
    />
  </video>
);

const Metaverse = () => (
  <BannerSection className="dark-background">
    <BannerContainer>
      <div className={styles.textWrapper}>
        <span className={styles.title}>Metaverse</span>
        <div className={styles.sliderWrapper}>
          <Slider
            slides={metaverseVideos}
            className={styles.slider}
            customClasses={{
              swiperSlide: styles.swiperSlide,
              slide: styles.slide,
            }}
            renderSlide={renderMetaverseVideo}
            settings={metaverseSliderSettings}
          />
        </div>
        <p className={styles.description}>
          We design and create your customized space in the Metaverse and
          wearables. We design and create your customized space in the Metaverse
          and wearables
        </p>
      </div>
    </BannerContainer>
  </BannerSection>
);

export default Metaverse;
