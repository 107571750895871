import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import styles from './YourBrandOnTheMetaverse.module.scss'

const YourBrandOnTheMetaverse = () => (
  <BannerSection id="metaverse">
    <BannerContainer className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          Your brand <br />
          on the <br />
          metaverse
        </span>
        <p className={styles.description}>
          We design and create your customized space in the Metaverse and wearables.
        </p>
        {/* <Button className={styles.button} text="KPHI Marketing" link="https://" /> */}
        <Button
          className={styles.button}
          text="Contact us"
          link="https://forms.gle/K7PgXRpcxP144qFCA"
        />
      </div>
      <img className={styles.image} src="/images/metaverse.svg" alt="Token" />
    </BannerContainer>
  </BannerSection>
)

export default YourBrandOnTheMetaverse
