export const languages = [{text: 'English'}, {text: 'Spanish'}]

export const languagesWithImage = [
  {text: 'En', image: '/images/icons/en-flag.svg'},
  {text: 'Es', image: '/images/icons/es-flag.svg'},
]

export const categories = [
  {
    title: 'Kephi Gallery',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
  {
    title: 'Community',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
  {
    title: 'Marketing',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
]

export const verticals = [
  {
    name: 'KephiGallery',
    logoUrl: '/images/logos/kephi-gallery.svg',
    isComingSoon: false,
    clickLabel: 'Visit the Art Gallery',
    link: 'https://exchange.kephi.io/',
  },
  // {name: 'RealEstate', logoUrl: '/images/logos/kephi-real-estate.svg', isComingSoon: true},
  {
    name: 'KephiMarketing',
    logoUrl: '/images/logos/kephi-marketing.svg',
    isComingSoon: false,
    hashLink: '/#metaverse',
  },
]

export const socials = [
  {
    name: 'facebook',
    icon: 'images/icons/facebook.svg',
    url: 'https://www.facebook.com/KephiGallery/',
  },
  {name: 'twitter', icon: 'images/icons/twitter.svg', url: 'https://twitter.com/KephiGallery'},
  {
    name: 'instragram',
    icon: 'images/icons/instagram.svg',
    url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  },
  // {
  //   name: 'youtube',
  //   icon: 'images/icons/youtube.svg',
  //   url: 'https://www.youtube.com/channel/UCgQtaGud_JYKWPzu05NxLhA',
  // },
  {name: 'github', icon: 'images/icons/github.svg', url: 'https://github.com/orgs/kephi-gallery/'},
  {
    name: 'linkedin',
    icon: 'images/icons/linkedin.svg',
    url: 'https://www.linkedin.com/company/kephi-gallery/',
  },
  {name: 'discord', icon: 'images/icons/discord.svg', url: 'https://discord.com/invite/YMgwGeWQ'},
  {name: 'telegram', icon: 'images/icons/telegram.svg', url: 'https://t.me/KephiGallery'},
]
