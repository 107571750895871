import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import YourBrandOnTheMetaverse from '../YourBrandOnTheMetaverse/YourBrandOnTheMetaverse'
import Metaverse from '../Metaverse/Metaverse'
import Welcome from '../Welcome/Welcome'

const Home = () => (
  <>
    <Header />
    <Welcome />
    <Metaverse />
    <YourBrandOnTheMetaverse />
    <Footer />
  </>
)

export default Home
