import classNames from 'classnames'

import styles from './Button.module.scss'

interface IButtonProps {
  className?: string
  text: string
  type?: string
  onClick?: () => void
  link?: string
}
const Button: React.FC<IButtonProps> = ({className, text, onClick, link}) => {
  return !!link ? (
    <a
      className={classNames(styles.button, className)}
      href={link}
      target="_blank"
      rel="noopener noreferrer">
      {text}
    </a>
  ) : (
    <button className={classNames(styles.button, className)} onClick={onClick}>
      {text}
    </button>
  )
}

export default Button
