import React from 'react'
import classNames from 'classnames'

import styles from './BannerContainer.module.scss'

interface IBannerContainerProps {
  className?: string
  children: React.ReactNode
}

const BannerContainer: React.FC<IBannerContainerProps> = ({className, children}) => (
  <div className={classNames(styles.container, className)}>{children}</div>
)

export default BannerContainer
