import React from 'react'
import classNames from 'classnames'

import styles from './BannerSection.module.scss'

interface IBannerSectionProps {
  id?: string
  className?: string
  children: React.ReactNode
}

const BannerSection: React.FC<IBannerSectionProps> = ({id, className, children}) => (
  <div id={id} className={classNames(styles.section, className)}>
    {children}
  </div>
)

export default BannerSection
