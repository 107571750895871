import {useState} from 'react'
import classNames from 'classnames'

import {languagesWithImage, socials} from '../../constants/arrays'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Dropdown from '../Dropdown/Dropdown'

import styles from './Footer.module.scss'

const Footer = () => {
  const [languageSelected, setLanguageSelected] = useState(languagesWithImage[0])

  const onChangeLanguage = (value: string) => {
    setLanguageSelected(languagesWithImage.filter(language => language.text === value)[0])
  }

  return (
    <BannerSection className="dark-background">
      <BannerContainer className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <img src="/images/logos/marketing.svg" alt="kphi-logo" />
          </div>
          <div className={classNames(styles.column, styles.lastColumn)}>
            <Dropdown
              className={styles.dropdown}
              value={languageSelected}
              setValue={(value: any) => onChangeLanguage(value)}
              options={languagesWithImage}
              isWithImage
              customClasses={{
                head: styles.languageDropdownHead,
                avatar: styles.languageDropdownAvatar,
                arrow: styles.languageDropdownArrow,
              }}
            />
            <div className={styles.socialsWrapper}>
              <div className={styles.socials}>
                {socials.map(social => (
                  <a
                    key={social.name}
                    className={styles.social}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img src={social.icon} alt="socialIcon" />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.row, styles.copyrightRow, styles.borderTop)}>
          <div className={styles.copyright}>
            Copyright © 2022 Kephi Blockchain Solutions LLC. All rights reserved
          </div>
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default Footer
