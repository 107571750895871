import {useState} from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import styles from './Dropdown.module.scss'

interface IDropdownProps {
  className?: string
  value: any
  setValue: (str: string) => void
  options: Array<any>
  isWithImage?: boolean
  name?: string
  customClasses?: {
    user?: string
    head?: string
    avatar?: string
    selection?: string
    arrow?: string
    body?: string
    option?: string
  }
}

const Dropdown: React.FC<IDropdownProps> = ({
  className,
  value,
  setValue,
  options,
  isWithImage,
  name,
  customClasses = {},
}) => {
  // const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const handleClick = (str: string) => {
    setValue(str)
    setVisible(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={classNames(styles.dropdown, className, {[styles.active]: visible})} id={name}>
        <div
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
          className={classNames(styles.head, customClasses.head)}
          onClick={() => setVisible(!visible)}>
          {!!value.image && (
            <img
              className={classNames(styles.avatar, customClasses.avatar)}
              src={value.image}
              alt="Avatar"
            />
          )}
          <div className={styles.selection}>{value.text ? value.text : value}</div>
          <div className={classNames(styles.arrow, customClasses.arrow)}>
            <img alt="" className={styles.image} src="images/icons/arrow-bottom.svg" />
          </div>
        </div>
        {!isWithImage ? (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {typeof options[0] === 'string'
                ? options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={classNames(styles.option, {
                        [styles.selectioned]: option === value,
                      })}
                      onClick={() => handleClick(option)}
                      key={option.text}>
                      {option.text}
                    </div>
                  ))
                : options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={classNames(
                        styles.option,
                        {
                          [styles.selectioned]: option.text === value,
                        },
                        option.text === value ? 'text-gradient' : '',
                      )}
                      onClick={() => handleClick(option.text)}
                      key={option.text}>
                      {!!option.image && <img alt="" className={styles.image} src={option.image} />}
                      <span className={styles.text}>{option.text}</span>
                    </div>
                  ))}
            </div>
          </div>
        ) : (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {options.map((option: any) => (
                <div
                  onKeyDown={() => {}}
                  tabIndex={0}
                  role="button"
                  className={classNames(
                    styles.option,
                    {
                      [styles.selectioned]: option.text === value,
                    },
                    option.text === value ? 'text-gradient' : '',
                  )}
                  onClick={() => handleClick(option.text)}
                  key={option.text}>
                  <img
                    alt=""
                    className={classNames(styles.avatar, customClasses.avatar)}
                    src={option.image}
                  />
                  <span className={styles.text}>{option.text}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
